import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import EmptyState from '../components/empty-state';
import styles from '../styles/course.module.css';

export const query = graphql`
	{
		cms {
			allCourses {
				id
				name
				slug
				tags {
					name
				}
				image {
					publicUrl
					gatsbyFile {
						childImageSharp {
							fluid(maxWidth: 700, maxHeight: 700) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`;

export default ({ data }) => (
	<Layout id={styles.course}>
		<SEO
			title='Home'
			keywords={['Courses', ...data.cms.allCourses.flatMap(({ tags }) => tags.map(({ name }) => name))]}
			description='IEEE SSCS AlexSC Courses'
		/>
		<h1>Courses</h1>
		<ul className='flex flex-wrap justify-around'>
			{data.cms.allCourses.length === 0 ? (
				<EmptyState />
			) : (
				data.cms.allCourses.map((node, ind) => (
					<li key={ind}>
						<Link to={`/courses/${node.slug}`}>
							{node.image ? (
								<Img
									className='volunteer-img rounded-lg'
									fluid={node.image.gatsbyFile.childImageSharp.fluid}
								/>
							) : (
								<div></div>
							)}
							<h2>{node.name}</h2>
						</Link>
					</li>
				))
			)}
		</ul>
	</Layout>
);
